import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContentText from '../components/page/Content/ContentText';
import ContentImage from '../components/page/Content/ContentImage';
import ContentWrapper from '../components/page/Content/ContentWrapper';
import SubHeader from '../components/page/SubHeader';

const Page = ({ data }) => {
  // Props
  const page = data.prismicPage.data;
  if (!page) return null;

  const body = page.body.map((slice, index) => {
    switch (slice.slice_type) {
      case ('image'):
        return <ContentImage key={'slice-' + index} slice={slice} />;
      case ('text'):
        return <ContentText key={'slice-' + index} slice={slice} />;
      default:
        return null;
    }
  })

  return (
    <Layout>
      <SEO 
        title={page.meta_title}
        description={page.meta_description}
        type='article'
      />
      <SubHeader
        title={page.title}
        text={page.description}
      />
      <ContentWrapper>
        {body}
      </ContentWrapper>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String!) {
    prismicPage(id: {eq: $id}) {
      data {
        body {
          ... on PrismicPageBodyText {
            slice_type
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPageBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid(maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPageBodyQuote {
            id
            slice_type
            primary {
              person {
                raw
              }
            }
          }
        }
        description {
          raw
        }
        meta_description
        meta_title
        title {
          raw
        }
      }
    }
  }
`;