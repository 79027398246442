import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Container } from './styles';

const ContentText = ({ slice }) => (
  <section>
    <Container>
      {RichText.render(slice.primary.text.raw)}
    </Container>
  </section>
);

export default ContentText;