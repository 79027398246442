import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../../../utils/styles';

export const Section = styled.header`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.textDark};
`;

export const Container = styled.div`
  max-width: ${dimensions.contentWidth};
  margin: 0 auto;
  padding: 0 24px;

  @media (min-width: ${breakpoints.xl}px){
    max-width: 850px;
  }
`;