import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { dimensions } from '../../../../utils/styles';

export const Image = styled(Img)`
  max-width: ${({ fullWidth }) => fullWidth ? dimensions.contentWidth : '850px'};
  margin: 0 auto;
`;

export const Section = styled.section`
  padding: ${({ fullWidth }) => fullWidth ? '32px 0 64px' : '32px 24px 64px'};
`;