import React from 'react';
import { Section, Image } from './styles';

const ContentImage = ({ slice }) => {
  let image
  if (slice.primary.image) {
    image = (
      <Image 
        fluid={slice.primary.image.fluid} 
        alt={slice.primary.image.alt}
        fullWidth={slice.primary.large_width}
      />
    );
  }
  return (
    <Section 
      data-sal='slide-up' 
      data-sal-easing='ease' 
      data-sal-duration='600'
      fullWidth={slice.primary.large_width}
    >
     {image}
    </Section>
  );
};

export default ContentImage;