import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Section, Container, Description } from './styles';

const SubHeader = ({ title, text, color }) => {
  return (
    <Section color={color}>
      <Container>
        <h1>
          {RichText.asText(title.raw)}
        </h1>
        <Description>
          {RichText.asText(text.raw)}
        </Description>
      </Container>
    </Section>
  );
};

export default SubHeader;