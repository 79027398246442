import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/styles';

export const Section = styled.section`
  background: ${({ color, theme }) => color ? color : theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 352px;
  padding: 32px;

  @media (max-width: ${breakpoints.l}px){
    padding: 24px;
  }

  @media (min-width: ${breakpoints.xl}px){
    height: 672px;
  }
`;

export const Container = styled.div`
  max-width: 610px;
  color: ${({ theme }) => theme.textLight};

  @media (min-width: ${breakpoints.xl}px){
    max-width: 850px;
  }
`;

export const Description = styled.p`
  margin-bottom: 0;

  @media (min-width: ${breakpoints.xl}px){
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;